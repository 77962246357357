import React from "react";
import Box from "@material-ui/core/Box";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useQueryParams } from "hooks/useQueryParams";
import { getSignupUrl, getUrlQuery, openUrl } from "lib/helpers/urls";
import { Card } from "components/Card";
import { Button } from "components/Button";
import { Text } from "components/Text";
import { Container } from "components/layouts/Container";
import { Columns } from "components/layouts/Columns";
import { Column } from "components/layouts/Column";
import { Link } from "components/Link";
import { IframeLayout } from "components/layouts/IframeLayout";
import { Trans, useTranslation } from "react-i18next";
import { Mobile, Router, MobileRouter } from "./icons/";
import { Tiles, TileSpan } from "components/layouts/Tiles";
import { Stack } from "components/layouts/Stack";
import { useCoopAgreement } from "hooks/queries/useCoopAgreement";
import { Spinner } from "components/Spinner";
import { FullScreenCenteredLayout } from "components/layouts/FullScreenCenteredLayout";
import { Heading } from "./Heading";
import { TopBarLayout } from "components/layouts/TopBarLayout";

const Content = () => {
  const { t } = useTranslation();

  const { currentUser } = useApplicationContext();
  const {
    opting_for_role: optingForRole,
    locale,
    coop_agreement_code: coopAgreementCode,
  } = useQueryParams();
  const queryParams = {
    opting_for_role: optingForRole,
    locale,
    coop_agreement_code: coopAgreementCode,
  };
  const searchCoopAgreement =
    optingForRole === "coop_agreement" && coopAgreementCode !== "SC";

  const {
    data: { name: coopAgreement, first_month_promotion: isPromotionActive } = {
      name: null,
    },
    isLoading,
  } = useCoopAgreement({
    automatically_run: searchCoopAgreement,
    coopAgreementCode,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (searchCoopAgreement && !coopAgreement) {
    return (
      <FullScreenCenteredLayout>
        <Tiles columns={1}>
          <Box mb={2}>
            <Trans i18nKey="funnel.join.coop_agreement.error">
              <Link
                target="_blank"
                to={"mailto:" + t("common.assistance_email")}
              />
            </Trans>
          </Box>
        </Tiles>
      </FullScreenCenteredLayout>
    );
  }

  return (
    <IframeLayout>
      <Container variant="wide">
        <Box mt={7} />
        <Heading
          optingForRole={optingForRole}
          coopAgreementCode={coopAgreementCode}
          coopAgreement={coopAgreement}
          isPromotionActive={isPromotionActive}
        />
        <Columns collapseOnSmallScreens align="center">
          <Column>
            <Link
              target="_parent"
              to={getUrlQuery("/tariffs/mobile", queryParams)}
              showUnderline={false}
            >
              <Card
                variant="cta"
                width={["100%", "auto"]}
                maxWidth={["auto", 350]}
              >
                <Box
                  minHeight={238}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  textAlign="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Mobile />
                    <Text bold size="lg">
                      {t("funnel.join.common.mobile")}
                    </Text>
                    <Box>
                      <Text size="sm">&nbsp;</Text>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="center">
                    <Button>{t("funnel.join.common.button")}</Button>
                  </Box>
                </Box>
              </Card>
            </Link>
          </Column>
          <Column>
            <Link
              target="_parent"
              to={getUrlQuery("/tariffs/internet", queryParams)}
              showUnderline={false}
            >
              <Card
                variant="cta"
                width={["100%", "auto"]}
                maxWidth={["auto", 350]}
              >
                <Box
                  minHeight={238}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  textAlign="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Router />
                    <Text bold size="lg">
                      {t("funnel.join.common.internet")}
                    </Text>
                    <Box>
                      <Text size="sm">
                        {t("funnel.join.common.subtitle_internet")}
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" justifySelf="center">
                    <Button>{t("funnel.join.common.button")}</Button>
                  </Box>
                </Box>
              </Card>
            </Link>
          </Column>
          <Column>
            <Link
              target="_parent"
              to={getUrlQuery("/tariffs/recommended", queryParams)}
              showUnderline={false}
            >
              <Card
                variant="cta"
                width={["100%", "auto"]}
                maxWidth={["auto", 350]}
              >
                <Box
                  minHeight={238}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  textAlign="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <MobileRouter />
                    <Text bold size="lg">
                      {t("funnel.join.common.both")}
                    </Text>
                    <Box>
                      <Text size="sm">
                        {t("funnel.join.common.subtitle_internet_mobile")}
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" justifySelf="center">
                    <Button>{t("funnel.join.common.button")}</Button>
                  </Box>
                </Box>
              </Card>
            </Link>
          </Column>
        </Columns>
        {!Boolean(currentUser) && !Boolean(optingForRole) && (
          <Box width="100%" mt={6}>
            <Tiles columns={9}>
              <div />
              <div />
              <TileSpan span={5}>
                <Box width="auto">
                  <Box mb={3}>
                    <Text semibold size="xl">
                      {t("funnel.product_picker.only_member.title")}
                    </Text>
                  </Box>
                  <Card variant="cta">
                    <Tiles columns={2}>
                      <Stack>
                        <Text bold>
                          {t("funnel.product_picker.only_member.subtitle")}
                        </Text>
                        <Text>
                          {t("funnel.product_picker.only_member.description")}
                        </Text>
                      </Stack>
                      <Button
                        onClick={() =>
                          openUrl(getSignupUrl([], "member"), "_parent")
                        }
                      >
                        {t("common.select")}
                      </Button>
                    </Tiles>
                  </Card>
                </Box>
              </TileSpan>
              <div />
              <div />
            </Tiles>
          </Box>
        )}
        <Box mb={9} />
      </Container>
    </IframeLayout>
  );
};
export const Join = ({ isTopBar }) => {
  return (
    <>
      {isTopBar ? (
        <TopBarLayout>
          <Content />
        </TopBarLayout>
      ) : (
        <Content />
      )}
    </>
  );
};

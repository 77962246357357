import { useMediaQuery, useTheme } from "@material-ui/core";
import { Button } from "components/Button";
import { Link } from "components/Link";
import { Text } from "components/Text";
import { useStore } from "hooks/useStore";
import { getLoginUrl, getSignupUrl, openUrl } from "lib/helpers/urls";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ModalLayout } from "components/layouts/ModalLayout";

export const ModalContractTariff = ({ isOpen, onClose }) => {
  const { isSharedLines, lines } = useStore();

  const onConfirm = () => {
    openUrl(
      getSignupUrl(lines, "member", undefined, true, isSharedLines),
      "_parent"
    );
  };

  const onWantsToBeSponsoredClick = () => {
    openUrl(
      getSignupUrl(lines, "sponsored", undefined, true, isSharedLines),
      "_parent"
    );
  };

  // const getLoginUrl = () =>
  //   `/home?redirect_to=${encodeURIComponent(
  //     getSignupUrl(lines, undefined, undefined, true, isSharedLines)
  //   )}`;

  const LoginLink = ({ children, url }) => {
    return (
      <Text textAlign="center" bold underline color="primary.main">
        <Link target="_parent" to={url}>
          {children}
        </Link>
      </Text>
    );
  };

  const { t } = useTranslation();
  const theme = useTheme();
  /* 
    value |0px     600px    960px  ...   
    key   |xs      sm       md     ...  
  */
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <Text size={isExtraSmallScreen ? "lg" : "xl"} lineHeight={1.25} bold>
        {t("funnel.tariffs.confirm_modal.title")}
      </Text>
      <Text>
        <Trans
          i18nKey={`funnel.tariffs.confirm_modal.body.${
            isExtraSmallScreen ? "mobile" : "desktop"
          }`}
        >
          <Text textAlign="center" bold lineHeight={1.375} />
          <LoginLink url={getLoginUrl(lines, isSharedLines)} />
        </Trans>
      </Text>
      <Button onClick={onConfirm}>
        {t("funnel.tariffs.confirm_modal.cta")}
      </Button>
      {!isExtraSmallScreen && (
        <Text textAlign="center" color="primary.main">
          <Trans
            i18nKey={
              "funnel.tariffs.confirm_modal.already_an_user_log_in.mobile"
            }
          >
            <LoginLink url={getLoginUrl(lines, isSharedLines)} />
          </Trans>
        </Text>
      )}

      <Text
        textAlign="center"
        onClick={onWantsToBeSponsoredClick}
        bold
        underline
        color="primary.main"
      >
        {t("funnel.tariffs.confirm_modal.sponsored")}
      </Text>
    </ModalLayout>
  );
};

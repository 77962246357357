import React, { useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Stack } from "components/layouts/Stack";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import MailIcon from "@material-ui/icons/Mail";
import EditIcon from "@material-ui/icons/Edit";
import RepeatIcon from "@material-ui/icons/Repeat";
import PersonIcon from "@material-ui/icons/Person";
import Avatar from "@material-ui/core/Avatar";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { useApplicationContext } from "hooks/useApplicationContext";
import { logout } from "lib/api/auth";
import { getJoinUrl, openUrl } from "lib/helpers/urls";
import { BrandLogo } from "components/icons/BrandLogo";
import { useAuthContext } from "lib/AuthContext";

const drawerWidth = 290;
const toolbarHeight = 64;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    justifyContent: "space-between",
    width: drawerWidth,
  },
  content: {
    display: "flex",
    flex: 1,
    flexGrow: 1,
  },
  contentWrapper: {
    display: "flex",
    flex: 1,
    paddingTop: `${toolbarHeight * 2}px`,
    padding: theme.spacing(3),
  },
  innerToolbar: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  menuButtonWrapper: {
    position: "absolute",
    left: 0,
  },
  logo: {
    height: "30px",
  },
  highlighted: {
    "color": theme.palette.background.light,
    "backgroundColor": theme.palette.primary.main,
    "& .MuiListItemIcon-root": {
      color: theme.palette.background.light,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const PersonalInfo = () => {
  const { currentUser } = useApplicationContext();
  const { t } = useTranslation();
  return (
    <Stack spacing="2" align="center">
      <Avatar />
      <strong>{currentUser.full_name}</strong>
      {currentUser?.cooperator_register_number > 0 && (
        <Typography variant="subtitle2" color="textSecondary">
          {t("common.cooperator_register_number", {
            cooperatorRegisterNumber: currentUser.cooperator_register_number,
          })}
        </Typography>
      )}
    </Stack>
  );
};

const MenuLink = ({ icon, to, text, onClick, isHighlighted = false }) => {
  // courtesy of https://material-ui.com/guides/composition/#list
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );
  const { highlighted } = useStyles();

  return (
    <ListItem
      button
      component={renderLink}
      className={isHighlighted ? highlighted : ""}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};

const MenuButton = ({ icon, text, onClick, isHighlighted = false }) => {
  const renderButton = React.forwardRef((props, ref) => (
    <button {...props} onClick={onClick} ref={ref} />
  ));

  const { highlighted } = useStyles();

  return (
    <ListItem
      button
      component={renderButton}
      className={isHighlighted ? highlighted : ""}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};

// "Inspired" by this demo https://material-ui.com/components/drawers/#responsive-drawer
export function SidebarLayout({ children, isLoading, ...props }) {
  const windowProps = props?.window;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState(null);
  const { currentUser } = useApplicationContext();
  const keycloak = useAuthContext();
  const isMember = currentUser.role === "member";
  const { is_new_service: isHireServiceBanned } =
    currentUser.profile_banned_actions;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();

  /* close mobile drawer when navigation changes */
  useEffect(() => {
    if (mobileOpen) {
      setMobileOpen(false);
    }
  }, [location]);

  useEffect(() => {
    if (localStorage.getItem("flash-message")) {
      setFlashMessage(localStorage.getItem("flash-message"));
      localStorage.removeItem("flash-message");
    }
  }, []);
  function onClickParticipate(e) {
    e.preventDefault();
    window.open(
      `https://participa.somconnexio.coop/users/sign_in?locale=${currentUser.preferred_locale}`
    );
  }
  async function onClickLogout() {
    await logout(() =>
      keycloak.logout({
        redirectUri: `${window.location.origin}/home`,
      })
    );
  }

  function onClickHireService() {
    openUrl(getJoinUrl(currentUser.preferred_locale, undefined, undefined));
  }

  const drawer = (
    <Fragment>
      <div>
        <div className={classes.toolbar} />

        <Box mb={4}>
          <PersonalInfo />
        </Box>

        <Divider />

        {/* closes menu when navigating anywhere */}
        <Box mt={4}>
          <List>
            <MenuLink
              icon={<AssignmentIcon />}
              text={t("summary.title")}
              to="/home"
            />
            <MenuLink
              icon={<MailIcon />}
              text={t("invoices.title")}
              to="/invoices"
            />
            <MenuLink
              icon={<PersonIcon />}
              text={t("profile.title")}
              to="/profile"
            />
            <MenuLink
              icon={<EditIcon />}
              text={t("request_change.title")}
              to="/request-change"
            />
            {isMember && (
              <MenuLink
                icon={<SupervisorAccountIcon />}
                text={t("sponsor.title")}
                to="/sponsor"
              />
            )}
            {!isHireServiceBanned && (
              <MenuButton
                onClick={onClickHireService}
                icon={<AddCircleIcon />}
                text={t("profile.hire_service")}
                isHighlighted={true}
              />
            )}
            <MenuLink
              icon={<RepeatIcon />}
              text={t("share_data.title")}
              to="/share-data"
            />
            <MenuButton
              icon={<HowToVoteIcon />}
              text={t("profile.participate")}
              onClick={(e) => onClickParticipate(e)}
            />
          </List>
        </Box>
      </div>
      <div>
        <List>
          <MenuButton
            onClick={onClickLogout}
            icon={<ExitToAppIcon />}
            text={t("common.logout")}
          />
        </List>
      </div>
    </Fragment>
  );

  const container =
    windowProps !== undefined ? () => windowProps().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.innerToolbar}>
            <div className={classes.menuButtonWrapper}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <BrandLogo variant="white" />
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentWrapper}>
          <Box display="flex" flex={1} justifyContent="center">
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              maxWidth={["100%", "600px"]}
            >
              {flashMessage && (
                <Alert
                  severity="success"
                  message={flashMessage}
                  onClose={() => setFlashMessage(null)}
                  style={{ marginBottom: "1rem" }}
                >
                  {flashMessage}
                </Alert>
              )}
              {children}
            </Box>
          </Box>
        </div>
      </main>
    </div>
  );
}

import { removeNonAlphanum, removeSpaces } from "lib/helpers/string";
import { preprocessUsernameForApi } from "../../../lib/domain/somconnexio/auth";

export const formToApi = (
  state,
  getLinesFromState,
  hasInternetLineInFormData,
  {
    loggedIn = false,
    mustPayMonthlyBill = false,
    mustPayMemberFee = false,
  } = {}
) => {
  const addressFrom = (value) => {
    if (!value) {
      return null;
    }

    const address = state.availableAddresses.find(
      (address) => value._id === address._id
    );

    return {
      street: address.street,
      zip_code: address.zip_code,
      city: address.city,
      state: address.state,
      country: "ES",
    };
  };

  const mapPersonalDataFields = (fields) => {
    const result = {
      is_company: fields.is_company,
      vat: preprocessUsernameForApi(removeSpaces(fields.vat)),
      name: fields.name,
      surname: fields.surname,
      lastname: fields.lastname,
      email: fields.email,
      phone: removeSpaces(fields.phone),
      tradename: fields.is_company ? fields.name : null,
      birthdate: fields.is_company
        ? null
        : fields.birthdate.split("/").reverse().join("-"),
      gender: fields.gender,
      lang: fields.lang,
      nationality: fields.nationality || "ES",
      discovery_channel_id: fields.discovery_channel_id,
    };

    if (fields.sponsor_vat) {
      result.sponsor_vat = preprocessUsernameForApi(
        removeSpaces(fields.sponsor_vat)
      );
    }
    if (state.coopAgreementCode) {
      result.coop_agreement_code = state.coopAgreementCode;
    }

    return result;
  };

  const mapPaymentFields = (fields) => {
    const reuseIbanOrNot = () => {
      const hasTwoIbanFields = mustPayMemberFee && mustPayMonthlyBill;

      if (!hasTwoIbanFields) {
        return fields.iban;
      }

      return fields.use_same_bank_account ? fields.service_iban : fields.iban;
    };

    const result = {};

    result.payment_type = fields.pays_in_ten_terms ? "split" : "single";

    if (fields.service_iban) {
      result.service_iban = removeNonAlphanum(
        removeSpaces(fields.service_iban)
      );
    }

    result.iban = removeNonAlphanum(removeSpaces(reuseIbanOrNot()));
    result.address = addressFrom(fields.address);
    result.delivery_address = addressFrom(fields.delivery_address);
    result.agrees_to_voluntary_contribution =
      fields.agrees_to_voluntary_contribution;
    return result;
  };

  const mapMobileLine = (deliveryAddress, line, hasInternetLineInFormData) => {
    return {
      type: "mobile",
      product_code: line.code,
      mobile_isp_info: {
        type: line.keep_number ? "portability" : "new",
        ...(line.has_sim_card ? { icc: line.icc } : {}),
        ...(line.is_prepaid ? { icc_donor: line.icc_donor } : {}),
        phone_number: removeSpaces(line.phone_number),
        ...(line.keep_number
          ? { previous_provider: line.previous_provider }
          : {}),
        previous_contract_type: line.is_prepaid ? "prepaid" : "contract",
        delivery_address: (!line.has_sim_card && deliveryAddress) || null,
        ...(line.other_person
          ? {
              previous_owner_vat: preprocessUsernameForApi(
                removeSpaces(line.previous_owner_vat)
              ),
              previous_owner_name: line.previous_owner_name,
              previous_owner_surname: line.previous_owner_surname,
              previous_owner_lastname: line.previous_owner_lastname,
            }
          : {}),
        ...(line?.is_offer_tariff && !hasInternetLineInFormData
          ? {
              fiber_linked_to_mobile_offer:
                state.fiberLinesToAssociateMobile[0].code,
            }
          : {}),
      },
    };
  };

  const mapInternetLine = (line) => ({
    type: "broadband",
    product_code: line.code,
    contact_phone: line?.contact_phone,
    broadband_isp_info: {
      type: line.already_has_service ? "portability" : "new",
      ...(!line.internet_without_phone
        ? { phone_number: removeSpaces(line.phone_number) }
        : {}),
      ...(line.already_has_service
        ? { previous_provider: line.previous_provider }
        : {}),
      delivery_address: addressFrom(line.service_address),
      service_address: addressFrom(line.service_address),
      previous_service: line.previous_broadband_service,
      ...(line.other_person & !line.internet_without_phone
        ? {
            previous_owner_vat: preprocessUsernameForApi(
              removeSpaces(line.previous_owner_vat)
            ),
            previous_owner_name: line.previous_owner_name,
            previous_owner_surname: line.previous_owner_surname,
            previous_owner_lastname: line.previous_owner_lastname,
          }
        : {}),
    },
  });

  let result = {};

  if (!loggedIn) {
    result = {
      ...result,
      ...mapPersonalDataFields({
        ...(state.formStepDataByKey["partner/personal-data"] || {}),
        ...(state.formStepDataByKey["partner/additional-data"] || {}),
      }),
    };
  }

  result = {
    ...result,
    ...mapPaymentFields({
      ...(state.formStepDataByKey["payment/member-fee"] || {}),
      ...(state.formStepDataByKey["payment/monthly-bill"] || {}),
      ...(state.formStepDataByKey["payment/shipment"] || {}),
    }),
  };

  const { delivery_address } = result;
  delete result.delivery_address;
  result.lines = getLinesFromState().map((line) =>
    line.type === "mobile"
      ? mapMobileLine(delivery_address, line, hasInternetLineInFormData)
      : mapInternetLine(line)
  );
  if (state?.isPromotionActive) {
    result = { ...result, is_promotion_active: true };
  }
  return result;
};

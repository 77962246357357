import React, { useState, useEffect, Suspense } from "react";
import "./App.css";

import i18next from "i18next";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import { initialize } from "./initializers/";
import { FullScreenCenteredLayout } from "./components/layouts/FullScreenCenteredLayout";
import { ApplicationContext } from "./lib/ApplicationContext";
import { tokenLogin, whoami } from "./lib/api/auth";
import AuthProvider, { useAuthContext } from "lib/AuthContext";
import { useApplicationContext } from "./hooks/useApplicationContext";

import { MaintenanceMode } from "./screens/MaintenanceMode";
import { Home } from "./screens/Home/";
import { Invoices } from "./screens/Invoices";
import { RequestChange } from "./screens/RequestChange";
import { Profile } from "./screens/Profile";
import { Tariffs } from "./screens/Tariffs";
import { SignUp } from "./screens/SignUp";
import { ConfirmEmail } from "./screens/ConfirmEmail";
import { DevIframe } from "./screens/DevIframe";
import { Spinner } from "./components/Spinner";
import { Modal } from "./components/Modal";
import { Text } from "./components/Text";
import { Tiles } from "./components/layouts/Tiles";
import { Button } from "./components/Button";
import { withTranslation } from "react-i18next";
import { AppErrorBoundary } from "./components/AppErrorBoundary";
import { Sponsor } from "./screens/Sponsor";
import { QueryClient, QueryClientProvider } from "react-query";
import { IdleTracker } from "./components/IdleTracker";
import { QueryParamProvider } from "use-query-params";
import { useStore } from "hooks/useStore";
import "console-goodies";
import { ShareData } from "screens/ShareData";
import keycloak from "./Keycloak";
import { useQueryParams } from "hooks/useQueryParams";
import { ConfirmChange } from "screens/ConfirmChange";
import { ChangeOwner } from "screens/ConfirmChange/changeOwner";
import { useAnalytics } from "hooks/useAnalytics";
import { Join } from "screens/Join";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // staleTime: 2 * 60 * 1000, // 2 min, https://www.codemzy.com/blog/react-query-cachetime-staletime
    },
  },
});

/**
 * Flip this boolean to enable/disable maintenance mode and deploy.
 */
const isMaintenanceMode = false;

const SplashScreen = () => (
  <FullScreenCenteredLayout>
    <Spinner />
  </FullScreenCenteredLayout>
);

// Using withTranslation HOC instead of useTranslation hook because there is
// some issue with rules of hooks and suspense.
const SessionExpiredModal = withTranslation()(({ t }) => {
  const isSessionExpired = useStore((state) => state.sessionExpired);
  const keycloak = useAuthContext();

  return (
    <Modal isOpen={isSessionExpired}>
      <Tiles columns={1}>
        <Text>{t("common.session_expired")}</Text>
        <Button
          onClick={() => {
            keycloak.logout({ redirectUri: window.location });
          }}
        >
          {t("common.login")}
        </Button>
      </Tiles>
    </Modal>
  );
});

const PrivateRoute = ({ children, ...rest }) => {
  const { currentUser } = useApplicationContext();
  const isAuthenticated = Boolean(currentUser);
  const keycloak = useAuthContext();
  const { redirect_to = window.location.pathname, locale = "ca" } =
    useQueryParams();

  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated
          ? children
          : keycloak.login({
              redirectUri: `${window.location.origin}${redirect_to}`,
              kcLocale: locale,
            })
      }
    />
  );
};

const BannedNewServiceRoute = ({ children, ...rest }) => {
  const { currentUser } = useApplicationContext();
  const isAuthenticated = Boolean(currentUser);
  return (
    <Route
      {...rest}
      render={() =>
        !isAuthenticated ? (
          children
        ) : !currentUser.profile_banned_actions.is_new_service ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { fromBannedNewServiceRoute: true },
            }}
          />
        )
      }
    />
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const UserSignup = () => {
  const { redirect_to = "/home" } = useQueryParams();
  const [path, queryString] = redirect_to.split("?");
  return (
    <Redirect
      to={{
        pathname: path,
        search: queryString ? `?${queryString}` : "",
      }}
    />
  );
};

const AppRoutes = () => {
  const { locale = "ca" } = useQueryParams();
  if (isMaintenanceMode) {
    return <MaintenanceMode />;
  }

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/login">
          <Redirect to={{ pathname: "/home", search: `?locale=${locale}` }} />
        </Route>

        <Route path="/confirm-email/:token">
          <ConfirmEmail />
        </Route>

        <PrivateRoute path="/home">
          <Home />
        </PrivateRoute>

        <PrivateRoute path="/profile">
          <Profile />
        </PrivateRoute>

        <PrivateRoute path="/invoices">
          <Invoices />
        </PrivateRoute>

        <PrivateRoute path="/request-change">
          <RequestChange />
        </PrivateRoute>

        <PrivateRoute path="/sponsor">
          <Sponsor />
        </PrivateRoute>

        <PrivateRoute path="/share-data">
          <ShareData />
        </PrivateRoute>

        <Route path="/confirm-change/owner/:token">
          <ChangeOwner />
        </Route>

        <Route path="/confirm-change/:token">
          <ConfirmChange />
        </Route>

        <Route path="/join">
          <Join isTopBar={true} />
        </Route>

        <Route path="/tariffs/:type">
          <Tariffs />
        </Route>

        <BannedNewServiceRoute path="/product-picker">
          <Join isTopBar={true} />
        </BannedNewServiceRoute>

        <BannedNewServiceRoute path="/signup">
          <SignUp />
        </BannedNewServiceRoute>

        <PrivateRoute path="/user/signup">
          <UserSignup />
        </PrivateRoute>

        <Route path="/__iframe">
          <DevIframe />
        </Route>

        <Route
          path="/web-somconnexio"
          component={() => {
            window.location.href = "https://somconnexio.coop/";
            return null;
          }}
        />

        <Route path="*" render={() => <Redirect to="/home" />} />
      </Switch>
    </>
  );
};

function App() {
  const [isInitializing, setIsInitializing] = useState(true);
  const [contextValue, setContextValue] = useState({ currentUser: null });

  const getUserInfo = async () => {
    if (!keycloak?.token) return Promise.reject();
    try {
      const isLoggedIn = await tokenLogin(keycloak.token);
      const currentUser = isLoggedIn ? await whoami() : null;
      setContextValue({ currentUser });
      return currentUser;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      await initialize();

      try {
        await keycloak.init({
          onLoad: "check-sso",
          silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
          checkLoginIframe: false,
        });
        await getUserInfo();
      } catch (err) {
        // Do nothing
      }

      setIsInitializing(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { currentUser } = contextValue;
      const localeFromUrl = new URLSearchParams(window.location.search).get(
        "locale"
      );

      if (!currentUser) {
        return;
      }

      if (!Boolean(localeFromUrl)) {
        await i18next.changeLanguage(currentUser.preferred_locale);
      }

      setIsInitializing(false);
    })();
  }, [contextValue]);

  const trackingOff = useAnalytics();

  return (
    <>
      <Suspense fallback={() => <SplashScreen />}>
        <AuthProvider keycloak={keycloak}>
          <ApplicationContext.Provider
            value={{ contextValue, setContextValue }}
          >
            <QueryParamProvider ReactRouterRoute={Route}>
              <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <IdleTracker />
                  <AppErrorBoundary>
                    {!isMaintenanceMode && isInitializing ? (
                      <SplashScreen />
                    ) : (
                      <AppRoutes />
                    )}
                  </AppErrorBoundary>
                  <SessionExpiredModal />
                </ThemeProvider>
              </QueryClientProvider>
            </QueryParamProvider>
          </ApplicationContext.Provider>
        </AuthProvider>
      </Suspense>
    </>
  );
}

export default App;

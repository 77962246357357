import { Box } from "@material-ui/core";
import { Heading as Header } from "components/Heading";
import { Tiles } from "components/layouts/Tiles";
import { Link } from "components/Link";
import { Subheading } from "components/Subheading";
import { Text } from "components/Text";
import { useApplicationContext } from "hooks/useApplicationContext";
import { getLocaleFor } from "initializers/initializeI18n";
import { Trans, useTranslation } from "react-i18next";

function PrmotionHeading({ optingForRole, coopAgreement }) {
  const { t } = useTranslation();
  const locale = getLocaleFor();
  return (
    <>
      <Header size="lg" gutterBottom>
        {t(`funnel.join.${optingForRole}.promotion.title`, {
          coopAgreement,
        })}
      </Header>
      <Tiles spacing={8}>
        <Box maxWidth={600}>
          <Subheading gutterBottom>
            {t(`funnel.join.${optingForRole}.promotion.subtitle`)}
          </Subheading>
          <Text paragraph>
            <Trans>
              {t(`funnel.join.${optingForRole}.promotion.text_1`, {
                coopAgreement,
              })}
            </Trans>
          </Text>
          <Text paragraph>
            <Trans>{t(`funnel.join.${optingForRole}.promotion.text_2`)}</Trans>
          </Text>
          <Text paragrap size="sm">
            <Trans i18nKey={"funnel.join.coop_agreement.promotion.text_3"}>
              <Link
                target="_blank"
                to={t("funnel.join.coop_agreement.promotion.terms_url")}
              />
            </Trans>
          </Text>
        </Box>
        <Box display="flex" justifyContent="center">
          <img
            height={"325px"}
            src={`/intercooperationAgreements_${locale}.png`}
            alt={"The best welcome to the best service"}
          />
        </Box>
      </Tiles>
    </>
  );
}

function PublicHeading({ optingForRole, coopAgreementCode, coopAgreement }) {
  const { t } = useTranslation();

  function resolveLiterals() {
    const isOptingForRole = Boolean(optingForRole);
    if (isOptingForRole) {
      return optingForRole === "coop_agreement" && coopAgreementCode === "SC"
        ? "self_agreement"
        : optingForRole;
    }
    return "anonymous";
  }

  const roleToLiterals = resolveLiterals();

  return (
    <>
      <Header size="lg" gutterBottom>
        {t(`funnel.join.${roleToLiterals}.title`, {
          coopAgreement,
        })}
      </Header>
      <Box maxWidth={600}>
        <Subheading gutterBottom>
          {t(`funnel.join.${roleToLiterals}.subtitle`)}
        </Subheading>
        <Text paragraph>
          {t(`funnel.join.${roleToLiterals}.text_1`, {
            coopAgreement,
          })}
        </Text>
        <Text bold paragraph>
          {t(`funnel.join.${roleToLiterals}.text_2`)}
        </Text>
      </Box>
    </>
  );
}

function AuthenticatedHeading() {
  const { t } = useTranslation();

  return (
    <Box mt={7}>
      <Header>{t("funnel.join.authenticated.title")}</Header>
      <Box maxWidth={600}>
        <Subheading gutterBottom>
          {t("funnel.join.authenticated.subtitle")}
        </Subheading>
      </Box>
    </Box>
  );
}
export const Heading = ({
  optingForRole,
  coopAgreementCode,
  coopAgreement,
  isPromotionActive,
}) => {
  const { currentUser } = useApplicationContext();
  return (
    <Box mb={8}>
      {currentUser ? (
        <AuthenticatedHeading />
      ) : isPromotionActive ? (
        <PrmotionHeading
          optingForRole={optingForRole}
          coopAgreement={coopAgreement}
        />
      ) : (
        <PublicHeading
          optingForRole={optingForRole}
          coopAgreementCode={coopAgreementCode}
          coopAgreement={coopAgreement}
        />
      )}
    </Box>
  );
};

import { useMediaQuery, useTheme } from "@material-ui/core";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { Link } from "components/Link";
import { Text } from "components/Text";
import { ModalLayout } from "components/layouts/ModalLayout";
import { Stack } from "components/layouts/Stack";
import { useQueryParams } from "hooks/useQueryParams";
import { useStore } from "hooks/useStore";
import { getLoginUrl, getSignupUrl, openUrl } from "lib/helpers/urls";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
const FirstStep = ({ onContract }) => {
  const { isSharedLines, lines, isPromotionActive } = useStore();
  const LoginLink = ({ children, url }) => {
    return (
      <Text textAlign="center" bold underline color="primary.main">
        <Link target="_parent" to={url}>
          {children}
        </Link>
      </Text>
    );
  };
  const onWantsToBeSponsoredClick = () => {
    openUrl(
      getSignupUrl(lines, "sponsored", undefined, true, isSharedLines),
      "_parent"
    );
  };
  const { t } = useTranslation();

  /* 
    value |0px     600px    960px  ...   
    key   |xs      sm       md     ...  
  */
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Stack align="center" spacing={4}>
      <Text
        marginBottom={0}
        size={isExtraSmallScreen ? "lg" : "xl"}
        lineHeight={1.25}
        bold
      >
        {t("funnel.tariffs.step_confirm_modal.first_step.title")}
      </Text>
      <Text>{t("funnel.tariffs.step_confirm_modal.first_step.body")}</Text>
      <Button onClick={onContract}>{t("common.contract")}</Button>
      <Text textAlign="center" color="primary.main">
        <Trans
          i18nKey={"funnel.tariffs.confirm_modal.already_an_user_log_in.mobile"}
        >
          <LoginLink
            url={getLoginUrl(lines, isSharedLines, isPromotionActive)}
          />
        </Trans>
      </Text>
      {!isPromotionActive && (
        <Text
          textAlign="center"
          onClick={onWantsToBeSponsoredClick}
          bold
          underline
          color="primary.main"
        >
          {t("funnel.tariffs.confirm_modal.sponsored")}
        </Text>
      )}
    </Stack>
  );
};

const SecondStep = () => {
  const { t } = useTranslation();
  const [role, setRole] = useState();
  const { isSharedLines, lines } = useStore();
  const onContract = () => {
    openUrl(
      getSignupUrl(
        lines,
        role === "partner" ? "member" : "coop_agreement",
        role === "partner" ? undefined : "SC",
        true,
        isSharedLines,
        "campaign_new_user"
      ),
      "_parent"
    );
  };

  /* 
    value |0px     600px    960px  ...   
    key   |xs      sm       md     ...  
  */
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Stack align="center" spacing={4}>
      <Text
        size={isExtraSmallScreen ? "lg" : "xl"}
        lineHeight={isExtraSmallScreen ? 0.5 : 1.25}
        bold
      >
        {t("funnel.tariffs.step_confirm_modal.second_step.title")}
      </Text>
      <>
        <Checkbox
          name="partner"
          label={
            <Text>
              {t("funnel.tariffs.step_confirm_modal.second_step.as_partner")}
            </Text>
          }
          onChange={({ target }) => setRole(target.name)}
          checked={role === "partner"}
        />
        <Checkbox
          name="user"
          label={
            <Text>
              {t("funnel.tariffs.step_confirm_modal.second_step.as_user")}
            </Text>
          }
          onChange={({ target }) => setRole(target.name)}
          checked={role === "user"}
        />
      </>
      {/* {role ? (
        <Text size="sm">
          <Trans
            i18nKey={`funnel.tariffs.step_confirm_modal.second_step.body_${role}`}
          />
        </Text>
      ) : ( */}
      <Text size="sm">
        <Trans
          i18nKey={`funnel.tariffs.step_confirm_modal.second_step.body.${
            isExtraSmallScreen ? "mobile" : "desktop"
          }`}
        />
      </Text>
      {/* )} */}
      <Button onClick={onContract} disabled={!role}>
        {t("common.contract")}
      </Button>
    </Stack>
  );
};
export const ModalStepCtaTariff = ({ isOpen, onClose }) => {
  const [step, setStep] = useState();
  const { isSharedLines, lines, isPromotionActive } = useStore();
  const { coop_agreement_code } = useQueryParams();
  useEffect(() => setStep((val) => (!isOpen ? 1 : val)), [isOpen]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      {(() => {
        if (step === 1) {
          return (
            <FirstStep
              onContract={() =>
                isPromotionActive
                  ? openUrl(
                      getSignupUrl(
                        lines,
                        "coop_agreement",
                        coop_agreement_code,
                        true,
                        isSharedLines,
                        false,
                        isPromotionActive
                      ),
                      "_parent"
                    )
                  : setStep(2)
              }
            />
          );
        } else if (step === 2) {
          return <SecondStep />;
        }
      })()}
    </ModalLayout>
  );
};

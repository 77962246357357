import React, { useState, useEffect, Suspense } from "react";
import "./App.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route, Redirect } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import { FullScreenCenteredLayout } from "./components/layouts/FullScreenCenteredLayout";

import { Spinner } from "./components/Spinner";
import { QueryClient, QueryClientProvider } from "react-query";
import { IdleTracker } from "./components/IdleTracker";
import "console-goodies";
import { initialize } from "initializers";
import { QueryParamProvider } from "use-query-params";
import { MaintenanceMode } from "screens/MaintenanceMode";
import { Tariffs } from "screens/Tariffs";
import { SignUp } from "screens/SignUp";
import { useAnalytics } from "./hooks/useAnalytics";
import { Join } from "screens/Join";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // staleTime: 2 * 60 * 1000, // 2 min, https://www.codemzy.com/blog/react-query-cachetime-staletime
    },
  },
});

/**
 * Flip this boolean to enable/disable maintenance mode and deploy.
 */
const isMaintenanceMode = false;

const AppRoutes = () => {
  if (isMaintenanceMode) return <MaintenanceMode />;

  return (
    <Switch>
      <Route path="/join">
        <Join isTopBar={false} />
      </Route>
      <Route path="/tariffs/:type">
        <Tariffs />
      </Route>
      <Route path="/product-picker">
        <Join isTopBar={false} />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="*" render={() => <Redirect to="/join" />} />
    </Switch>
  );
};

const SplashScreen = () => (
  <FullScreenCenteredLayout>
    <Spinner />
  </FullScreenCenteredLayout>
);

function Forms({ route }) {
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    (async () => {
      await initialize();
      setIsInitializing(false);
    })();
  }, []);

  const trackingOff = useAnalytics();

  return (
    <>
      <Suspense fallback={() => <SplashScreen />}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <IdleTracker />
              {!isMaintenanceMode && isInitializing ? (
                <SplashScreen />
              ) : (
                <AppRoutes />
              )}
            </ThemeProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </Suspense>
    </>
  );
}

export default Forms;
